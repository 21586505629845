import React, { useState } from "react"
import { Button } from "./ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog"
import { Input } from "./ui/input"
import { Label } from "./ui/label"
import { MessageSquare, User, Mail, Lightbulb, Send } from "lucide-react"

export function FeedbackModal() {
  const [open, setOpen] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [feedback, setFeedback] = useState("")

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      const response = await fetch('http://localhost:5000/api/send-feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, feedback }),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log('Feedback submitted successfully:', data)
      setSubmitted(true)
    } catch (error) {
      console.error('Error:', error)
      // You might want to show an error message to the user here
    }
  }

  return (
    <>
      <Button variant="ghost" className="w-full justify-start" onClick={() => setOpen(true)}>
        <MessageSquare className="mr-2 h-4 w-4" />
        Feedback
      </Button>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2 text-2xl">
              <Lightbulb className="w-6 h-6 text-yellow-500" />
              Prototype Feedback
            </DialogTitle>
            <DialogDescription className="text-base">
              This app is a prototype exploring how the Elgato Stream Deck can implement advanced automation workflows through a canvas editor.
            </DialogDescription>
          </DialogHeader>
          {!submitted ? (
            <form onSubmit={handleSubmit} className="grid gap-6 py-4">
              <div className="grid gap-2">
                <Label htmlFor="name" className="flex items-center gap-2">
                  <User className="w-4 h-4" />
                  Name
                </Label>
                <Input 
                  id="name" 
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="col-span-3" 
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="email" className="flex items-center gap-2">
                  <Mail className="w-4 h-4" />
                  Email
                </Label>
                <Input 
                  id="email" 
                  type="email" 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="col-span-3" 
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="feedback" className="flex items-center gap-2">
                  <MessageSquare className="w-4 h-4" />
                  Feedback
                </Label>
                <textarea 
                  id="feedback" 
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  className="col-span-3 min-h-[100px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50" 
                  placeholder="Share your thoughts, feature requests, or ideas for improvement..." 
                />
              </div>
              <Button type="submit" className="w-full">
                <Send className="w-4 h-4 mr-2" />
                Submit Feedback
              </Button>
            </form>
          ) : (
            <div className="py-6 text-center">
              <div className="w-16 h-16 mx-auto mb-4 rounded-full bg-green-100 flex items-center justify-center">
                <Lightbulb className="w-8 h-8 text-green-600" />
              </div>
              <h3 className="text-xl font-medium mb-2">Thank you for your feedback!</h3>
              <p className="text-sm text-muted-foreground">Your input is valuable in improving this prototype.</p>
              <Button onClick={() => {setSubmitted(false); setOpen(false); setName(""); setEmail(""); setFeedback("");}} className="mt-6">
                Close
              </Button>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}
import React from 'react';
import { NodeData } from '../types/types';
import { NodeCard } from './NodeCard';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { AddActionMenu } from './AddActionMenu';
import { cn } from "@/lib/utils";
import { DotPattern } from "@/components/ui/dot-pattern";
import { Sparkles } from 'lucide-react';
import { motion } from 'framer-motion';

interface CanvasProps {
  nodes: NodeData[];
  onDragEnd: (result: DropResult) => void;
  onEditNode: (id: string) => void;
  onTitleChange: (id: string, newTitle: string) => void;
  onDuplicateNode: (index: number) => void;
  onDeleteNode: (index: number) => void;
  onAddPlugin: () => void;
  onBrowseIntegrations: () => void;
  onAddElgatoTools: () => void;
  onAddNode: (nodeOrIndex: NodeData | number) => void;
}

export function Canvas({
  nodes,
  onDragEnd,
  onEditNode,
  onTitleChange,
  onDuplicateNode,
  onDeleteNode,
  onAddPlugin,
  onBrowseIntegrations,
  onAddElgatoTools,
  onAddNode
}: CanvasProps) {
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const itemData = e.dataTransfer.getData('text/plain');
    const category = e.dataTransfer.getData('text/category');
    const item = JSON.parse(itemData);
    
    const newNode: NodeData = {
      id: Date.now().toString(),
      name: `${item.pluginName}:${item.name}`,
      category: category,
      icon: item.logo || 'default-icon',
    };

    onAddNode(newNode);
  };

  return (
    <div 
      className="relative w-full h-full bg-background"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <DotPattern
        width={32}
        height={32}
        cx={16}
        cy={16}
        cr={0.8}
        className={cn(
          "absolute inset-0 w-full h-full text-foreground/[0.1]",
          "[mask-image:radial-gradient(ellipse_at_center,white_50%,transparent_80%)]"
        )}
      />
      <div className="absolute inset-0 overflow-auto">
        <div className="min-h-full w-full p-4 flex flex-col items-center">
          <div className={cn(
            "w-full max-w-2xl space-y-4",
            nodes.length > 0 && "pt-[20vh]" // Add 20% top padding when there are nodes
          )}>
            {nodes.length === 0 ? (
              <div className="flex items-center justify-center h-[calc(100vh-8rem)]">
                <div className="relative">
                  <motion.div
                    className="absolute inset-0 bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 rounded-lg opacity-75 blur-xl"
                    animate={{
                      background: [
                        'linear-gradient(to right, #ec4899, #ef4444, #eab308)',
                        'linear-gradient(to right, #3b82f6, #8b5cf6, #d946ef)',
                        'linear-gradient(to right, #22c55e, #10b981, #14b8a6)',
                      ],
                    }}
                    transition={{ duration: 5, repeat: Infinity, repeatType: 'reverse' }}
                  />
                  <motion.div
                    className="relative w-[480px] h-[320px] bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-1 rounded-lg"
                    animate={{
                      background: [
                        'linear-gradient(to right, #ec4899, #ef4444, #eab308)',
                        'linear-gradient(to right, #3b82f6, #8b5cf6, #d946ef)',
                        'linear-gradient(to right, #22c55e, #10b981, #14b8a6)',
                      ],
                    }}
                    transition={{ duration: 5, repeat: Infinity, repeatType: 'reverse' }}
                  >
                    <div className="absolute inset-[1px] bg-card rounded-lg p-8">
                      <div className="text-center">
                        <div className="mx-auto bg-muted w-16 h-16 rounded-full flex items-center justify-center mb-6">
                          <Sparkles className="h-8 w-8 text-muted-foreground" />
                        </div>
                        <h2 className="text-2xl font-semibold mb-3 text-card-foreground">
                          Set your trigger
                        </h2>
                        <p className="text-base text-muted-foreground mb-6">
                          If this happens...
                        </p>
                      </div>
                      <button
                        className="w-full bg-primary text-primary-foreground hover:bg-primary/90 py-3 px-6 rounded-md text-lg font-medium"
                        onClick={onAddPlugin}
                      >
                        Set up trigger
                      </button>
                    </div>
                  </motion.div>
                </div>
              </div>
            ) : (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="nodes">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} className="w-full space-y-4">
                      {nodes.map((node, index) => (
                        <React.Fragment key={node.id}>
                          <Draggable draggableId={node.id} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="flex justify-center"
                              >
                                <NodeCard
                                  node={node}
                                  onEdit={() => onEditNode(node.id)}
                                  onTitleChange={(newTitle) => onTitleChange(node.id, newTitle)}
                                  onDuplicate={() => onDuplicateNode(index)}
                                  onDelete={() => onDeleteNode(index)}
                                />
                              </div>
                            )}
                          </Draggable>
                          <div className="flex justify-center">
                            <AddActionMenu
                              onAddPlugin={onAddPlugin}
                              onBrowseIntegrations={onBrowseIntegrations}
                              onAddElgatoTools={onAddElgatoTools}
                            />
                          </div>
                        </React.Fragment>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import { LucideIcon } from "lucide-react"
import { cn } from "../../lib/utils"
import { Button } from "./button"

interface NavProps {
  isCollapsed: boolean
  links: {
    title: string
    icon: LucideIcon
    variant: "default" | "ghost"
    onClick?: () => void
  }[]
}

export function Nav({ links, isCollapsed }: NavProps) {
  return (
    <div
      data-collapsed={isCollapsed}
      className="group flex flex-col gap-2 py-2 px-2"
    >
      {links.map((link, index) => (
        <Button
          key={index}
          variant={link.variant}
          className={cn(
            "w-full",
            isCollapsed ? "h-9 w-9 p-0 mx-auto flex justify-center" : "justify-start px-3"
          )}
          onClick={link.onClick}
        >
          <link.icon className="h-4 w-4" />
          {!isCollapsed && <span className="ml-2">{link.title}</span>}
        </Button>
      ))}
    </div>
  )
} 
import React, { useEffect, useRef, useState } from 'react';

import { motion, useMotionValue, useTransform, animate } from 'framer-motion';



interface NumberTickerProps {

  value: number;

  delay?: number;

}



export const NumberTicker: React.FC<NumberTickerProps> = ({ value, delay = 0 }) => {

  const ref = useRef<HTMLSpanElement>(null);

  const motionValue = useMotionValue(0);

  const rounded = useTransform(motionValue, (latest) => Math.round(latest));

  const [isAnimating, setIsAnimating] = useState(false);



  useEffect(() => {

    const timer = setTimeout(() => {

      setIsAnimating(true);

    }, delay);



    return () => clearTimeout(timer);

  }, [delay]);



  useEffect(() => {

    if (isAnimating) {

      const controls = animate(motionValue, value, {

        duration: 1,

        ease: 'easeInOut',

      });



      return controls.stop;

    }

  }, [value, motionValue, isAnimating]);



  return <motion.span ref={ref}>{isAnimating ? rounded : 0}</motion.span>;

};



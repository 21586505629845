import React, { useState } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog"
import { Button } from "./ui/button"
import { ScrollArea } from "./ui/scroll-area"
import { Input } from "./ui/input"
import { Video, MessageSquare, Share2, AlertTriangle, Search, Tag, Gamepad, Mic, Camera, Music, Briefcase, Code, Headphones, Phone, Globe, Monitor, Coffee, Zap, Cloud, Layers, Wifi, Smartphone, Tv, Laptop } from 'lucide-react'
import { motion } from 'framer-motion'
import { Template } from '../types/types'

export const templates: Template[] = [
  {
    id: '1',
    name: 'Stream Health Monitor & Auto-Adjust',
    description: 'Monitor stream health and automatically adjust settings for optimal performance',
    category: 'Streaming',
    icon: Video,
    nodes: [
      { id: '1', title: 'Detect Frame Drops', action: 'OBS:MonitorFrameRate', category: 'Triggers' },
      { id: '2', title: 'Adjust Bitrate', action: 'OBS:SetBitrate', category: 'Actions' },
      { id: '3', title: 'Notify Discord', action: 'Discord:SendMessage', category: 'Actions' },
      { id: '4', title: 'Log to File', action: 'System:WriteToFile', category: 'Actions' },
    ]
  },
  {
    id: '2',
    name: 'Smart Home Studio Control',
    description: 'Control your smart home devices based on your streaming status',
    category: 'IoT',
    icon: Globe,
    nodes: [
      { id: '1', title: 'Stream Start', action: 'OBS:StreamStart', category: 'Triggers' },
      { id: '2', title: 'Turn On "On Air" Sign', action: 'IFTTT:TriggerWebhook', category: 'Actions' },
      { id: '3', title: 'Dim Philips Hue Lights', action: 'PhilipsHue:SetBrightness', category: 'Actions' },
      { id: '4', title: 'Set AC to Optimal Temp', action: 'Nest:SetTemperature', category: 'Actions' },
    ]
  },
  {
    id: '3',
    name: 'Cross-Platform Content Distribution',
    description: 'Automatically distribute your content across multiple platforms',
    category: 'Content Creation',
    icon: Share2,
    nodes: [
      { id: '1', title: 'New YouTube Upload', action: 'YouTube:NewVideoUploaded', category: 'Triggers' },
      { id: '2', title: 'Create Twitter Post', action: 'Twitter:CreateTweet', category: 'Actions' },
      { id: '3', title: 'Share on Facebook', action: 'Facebook:CreatePost', category: 'Actions' },
      { id: '4', title: 'Update Website', action: 'WordPress:CreatePost', category: 'Actions' },
    ]
  },
  {
    id: '4',
    name: 'Advanced Audio Workflow',
    description: 'Enhance your audio setup with automated processing and routing',
    category: 'Audio',
    icon: Headphones,
    nodes: [
      { id: '1', title: 'Audio Input Detected', action: 'AudioInterface:SignalDetected', category: 'Triggers' },
      { id: '2', title: 'Apply Noise Reduction', action: 'VST:ApplyPlugin', category: 'Actions' },
      { id: '3', title: 'Route to Virtual Cable', action: 'System:SetAudioOutput', category: 'Actions' },
      { id: '4', title: 'Adjust OBS Audio Levels', action: 'OBS:SetAudioSourceVolume', category: 'Actions' },
    ]
  },
  {
    id: '5',
    name: 'Streamlined Customer Support',
    description: 'Automate customer support workflows for faster response times',
    category: 'Customer Support',
    icon: MessageSquare,
    nodes: [
      { id: '1', title: 'New Zendesk Ticket', action: 'Zendesk:NewTicket', category: 'Triggers' },
      { id: '2', title: 'Categorize Ticket', action: 'AI:AnalyzeText', category: 'Actions' },
      { id: '3', title: 'Assign to Agent', action: 'Zendesk:AssignTicket', category: 'Actions' },
      { id: '4', title: 'Send Slack Notification', action: 'Slack:SendMessage', category: 'Actions' },
    ]
  },
  {
    id: '6',
    name: 'Emergency Dispatch Assistance',
    description: 'Streamline emergency response workflows for 911 operators',
    category: 'Emergency Services',
    icon: Phone,
    nodes: [
      { id: '1', title: 'Emergency Call Received', action: 'DispatchSystem:NewCall', category: 'Triggers' },
      { id: '2', title: 'Geo-locate Caller', action: 'Maps:GetLocation', category: 'Actions' },
      { id: '3', title: 'Dispatch Nearest Unit', action: 'DispatchSystem:AssignUnit', category: 'Actions' },
      { id: '4', title: 'Update Digital Board', action: 'StreamDeck:UpdateKey', category: 'Actions' },
    ]
  },
  {
    id: '7',
    name: 'Hybrid Work-From-Home Setup',
    description: 'Optimize your work environment for remote and in-office days',
    category: 'Productivity',
    icon: Briefcase,
    nodes: [
      { id: '1', title: 'Calendar Event Start', action: 'GoogleCalendar:EventStart', category: 'Triggers' },
      { id: '2', title: 'Set Slack Status', action: 'Slack:UpdateStatus', category: 'Actions' },
      { id: '3', title: 'Adjust Room Lighting', action: 'PhilipsHue:SetScene', category: 'Actions' },
      { id: '4', title: 'Launch Required Apps', action: 'System:LaunchApplication', category: 'Actions' },
    ]
  },
  {
    id: '8',
    name: 'Content Creator Studio Control',
    description: 'Manage your entire content creation setup with a single touch',
    category: 'Content Creation',
    icon: Camera,
    nodes: [
      { id: '1', title: 'Toggle Studio Lights', action: 'SmartHome:ToggleLights', category: 'Actions' },
      { id: '2', title: 'Start OBS Recording', action: 'OBS:StartRecording', category: 'Actions' },
      { id: '3', title: 'Activate Noise Cancellation', action: 'Audio:EnableNoiseReduction', category: 'Actions' },
      { id: '4', title: 'Set Do Not Disturb', action: 'System:EnableDND', category: 'Actions' },
    ]
  },
  {
    id: '9',
    name: 'Remote Work Productivity Hub',
    description: 'Optimize your work-from-home environment instantly',
    category: 'Remote Work',
    icon: Coffee,
    nodes: [
      { id: '1', title: 'Launch Work Apps', action: 'System:LaunchApplications', category: 'Actions' },
      { id: '2', title: 'Connect to VPN', action: 'Network:ConnectVPN', category: 'Actions' },
      { id: '3', title: 'Set Slack Status', action: 'Slack:UpdateStatus', category: 'Actions' },
      { id: '4', title: 'Start Time Tracking', action: 'Toggl:StartTimer', category: 'Actions' },
    ]
  },
  {
    id: '10',
    name: 'B2B Sales Automation',
    description: 'Streamline your sales process and boost productivity',
    category: 'B2B',
    icon: Briefcase,
    nodes: [
      { id: '1', title: 'Sync CRM Data', action: 'Salesforce:SyncData', category: 'Actions' },
      { id: '2', title: 'Generate Sales Report', action: 'Excel:GenerateReport', category: 'Actions' },
      { id: '3', title: 'Schedule Follow-up', action: 'Calendar:CreateEvent', category: 'Actions' },
      { id: '4', title: 'Update Pipeline', action: 'Trello:MoveCard', category: 'Actions' },
    ]
  },
  {
    id: '11',
    name: 'Hybrid Cloud-Local File Management',
    description: 'Seamlessly manage files across cloud and local storage',
    category: 'Productivity',
    icon: Layers,
    nodes: [
      { id: '1', title: 'Sync Local Folder', action: 'System:SyncFolder', category: 'Actions' },
      { id: '2', title: 'Upload to Cloud', action: 'Dropbox:UploadFiles', category: 'Actions' },
      { id: '3', title: 'Generate Shareable Link', action: 'GoogleDrive:CreateLink', category: 'Actions' },
      { id: '4', title: 'Backup to External Drive', action: 'System:BackupFiles', category: 'Actions' },
    ]
  },
  {
    id: '12',
    name: 'Smart Home Office Integration',
    description: 'Control your home office environment with Stream Deck',
    category: 'IoT',
    icon: Zap,
    nodes: [
      { id: '1', title: 'Adjust Standing Desk', action: 'SmartDesk:SetHeight', category: 'Actions' },
      { id: '2', title: 'Control Room Temperature', action: 'SmartThermostat:SetTemperature', category: 'Actions' },
      { id: '3', title: 'Manage Video Call Lighting', action: 'PhilipsHue:SetScene', category: 'Actions' },
      { id: '4', title: 'Toggle Noise-Cancelling', action: 'SmartSpeaker:ToggleNC', category: 'Actions' },
    ]
  },
  {
    id: '13',
    name: 'Multi-Platform Streaming Setup',
    description: 'Manage streams across multiple platforms simultaneously',
    category: 'Streaming',
    icon: Wifi,
    nodes: [
      { id: '1', title: 'Start Multi-Stream', action: 'Restream:StartBroadcast', category: 'Actions' },
      { id: '2', title: 'Sync Chat Across Platforms', action: 'ChatBot:SyncMessages', category: 'Actions' },
      { id: '3', title: 'Toggle Stream Overlays', action: 'OBS:ToggleScenes', category: 'Actions' },
      { id: '4', title: 'Monitor Stream Health', action: 'StreamAnalytics:CheckStatus', category: 'Actions' },
    ]
  },
  {
    id: '14',
    name: 'Cross-Device Content Syncing',
    description: 'Seamlessly manage content across all your devices',
    category: 'Productivity',
    icon: Smartphone,
    nodes: [
      { id: '1', title: 'Sync Clipboard', action: 'System:SyncClipboard', category: 'Actions' },
      { id: '2', title: 'Push URL to Phone', action: 'PushBullet:SendURL', category: 'Actions' },
      { id: '3', title: 'Mirror Notifications', action: 'System:MirrorNotifications', category: 'Actions' },
      { id: '4', title: 'Continue Task on Mobile', action: 'TaskManager:SwitchDevice', category: 'Actions' },
    ]
  },
  {
    id: '15',
    name: 'Advanced Video Editing Workflow',
    description: 'Streamline your video editing process with custom macros',
    category: 'Content Creation',
    icon: Tv,
    nodes: [
      { id: '1', title: 'Import Assets', action: 'PremierePro:ImportMedia', category: 'Actions' },
      { id: '2', title: 'Apply LUT Preset', action: 'PremierePro:ApplyLUT', category: 'Actions' },
      { id: '3', title: 'Add Sound Effects', action: 'System:InsertAudioFile', category: 'Actions' },
      { id: '4', title: 'Render Preview', action: 'PremierePro:RenderPreview', category: 'Actions' },
    ]
  },
]

interface TemplatesDialogProps {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  onUseTemplate: (template: Template) => void
}

export function TemplatesDialog({ isOpen, onOpenChange, onUseTemplate }: TemplatesDialogProps) {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)

  const categories = Array.from(new Set(templates.map(t => t.category)))

  const filteredTemplates = templates.filter(template => 
    template.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (!selectedCategory || template.category === selectedCategory)
  )

  const handleUseTemplate = (template: Template) => {
    onUseTemplate(template)
    onOpenChange(false) // Close the dialog
  }

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[900px] h-[80vh] flex flex-col">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold">Workflow Templates</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col space-y-4 my-4">
          <div className="relative">
            <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
            <Input 
              type="search" 
              placeholder="Search templates..." 
              className="pl-8" 
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="flex flex-wrap gap-2">
            <Button
              variant={selectedCategory === null ? "default" : "outline"}
              size="sm"
              onClick={() => setSelectedCategory(null)}
            >
              All Categories
            </Button>
            {categories.map(category => (
              <Button
                key={category}
                variant={selectedCategory === category ? "default" : "outline"}
                size="sm"
                onClick={() => setSelectedCategory(selectedCategory === category ? null : category)}
              >
                <Tag className="mr-2 h-4 w-4" />
                {category}
              </Button>
            ))}
          </div>
        </div>
        <ScrollArea className="flex-grow pr-4">
          <motion.div 
            className="grid grid-cols-1 md:grid-cols-2 gap-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            {filteredTemplates.map((template) => (
              <motion.div
                key={template.id}
                className="bg-card text-card-foreground rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <div className="p-6">
                  <div className="flex items-center mb-4">
                    <div className="bg-primary/10 p-3 rounded-full mr-4">
                      <template.icon className="h-6 w-6 text-primary" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold">{template.name}</h3>
                      <p className="text-sm text-muted-foreground">{template.category}</p>
                    </div>
                  </div>
                  <p className="text-sm text-muted-foreground mb-4">{template.description}</p>
                  <div className="flex justify-end">
                    <Button onClick={() => handleUseTemplate(template)} variant="outline">
                      Use Template
                    </Button>
                  </div>
                </div>
                <div className="bg-muted px-6 py-4">
                  <h4 className="text-sm font-medium mb-2">Included Actions:</h4>
                  <ul className="text-xs text-muted-foreground">
                    {template.nodes.map((node) => (
                      <li key={node.id} className="mb-1 flex items-center">
                        <span className="w-2 h-2 bg-primary rounded-full mr-2"></span>
                        {node.title}
                      </li>
                    ))}
                  </ul>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}
import React, { useState, useMemo } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog"
import { Input } from "./ui/input"
import { Button } from "./ui/button"
import { ScrollArea } from "./ui/scroll-area"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs"
import { categories } from '../data/categories'
import * as Icons from 'react-icons/fa'
import * as SiIcons from 'react-icons/si'
import * as MdIcons from 'react-icons/md'
import * as GiIcons from 'react-icons/gi'
import { Category, ActionItem, IconType } from '../types/types'

interface AddActionDialogProps {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  onAddAction: (action: string, category: string, pluginName: string) => void
}

export function AddActionDialog({ isOpen, onOpenChange, onAddAction }: AddActionDialogProps) {
  const [searchTerm, setSearchTerm] = useState('')
  const [activeTab, setActiveTab] = useState('Popular')

  const getIconComponent = (iconName: string): IconType => {
    if (iconName.startsWith('Fa')) return (Icons as any)[iconName] as IconType;
    if (iconName.startsWith('Si')) return (SiIcons as any)[iconName] as IconType;
    if (iconName.startsWith('Md')) return (MdIcons as any)[iconName] as IconType;
    if (iconName.startsWith('Gi')) return (GiIcons as any)[iconName] as IconType;
    return Icons.FaQuestionCircle as IconType;
  }

  const allActions = useMemo(() => categories.flatMap(category => category.items), [])

  const filteredActions = useMemo(() => allActions.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.pluginName.toLowerCase().includes(searchTerm.toLowerCase())
  ), [allActions, searchTerm])

  const popularActions = useMemo(() => [
    { name: "Toggle Scene", pluginName: "OBS Studio", logo: "SiObsstudio" },
    { name: "Adjust Volume", pluginName: "Wave Link", logo: "SiElgato" },
    { name: "Toggle Mute", pluginName: "Discord", logo: "FaDiscord" },
    { name: "Change Profile", pluginName: "Stream Deck", logo: "SiElgato" },
    { name: "Start Stream", pluginName: "Twitch", logo: "FaTwitch" },
    { name: "Play/Pause", pluginName: "Spotify", logo: "FaSpotify" },
    { name: "Post Tweet", pluginName: "Twitter", logo: "FaTwitter" },
    { name: "Toggle Key Light", pluginName: "Key Light", logo: "SiElgato" },
    { name: "Take Screenshot", pluginName: "Capture", logo: "SiElgato" },
  ], [])

  const videoActions = useMemo(() => allActions.filter(item => 
    ["OBS Studio", "Camera Hub", "Capture", "YouTube", "Twitch"].includes(item.pluginName)
  ), [allActions])

  const audioActions = useMemo(() => allActions.filter(item => 
    ["Wave Link", "Spotify", "Discord"].includes(item.pluginName)
  ), [allActions])

  const utilityActions = useMemo(() => allActions.filter(item => 
    ["Stream Deck", "Key Light", "Twitter"].includes(item.pluginName)
  ), [allActions])

  const getDisplayActions = () => {
    if (searchTerm) return filteredActions
    switch (activeTab) {
      case 'Popular': return popularActions
      case 'Video': return videoActions
      case 'Audio': return audioActions
      case 'Utilities': return utilityActions
      default: return popularActions
    }
  }

  const displayActions = getDisplayActions()

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add Plugin</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <Input
            placeholder="Search plugins..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Tabs defaultValue="Popular" className="w-full">
            <TabsList className="grid w-full grid-cols-4">
              <TabsTrigger value="Popular" onClick={() => setActiveTab('Popular')}>Popular</TabsTrigger>
              <TabsTrigger value="Video" onClick={() => setActiveTab('Video')}>Video</TabsTrigger>
              <TabsTrigger value="Audio" onClick={() => setActiveTab('Audio')}>Audio</TabsTrigger>
              <TabsTrigger value="Utilities" onClick={() => setActiveTab('Utilities')}>Utilities</TabsTrigger>
            </TabsList>
            <TabsContent value={activeTab}>
              <ScrollArea className="h-[300px] pr-4">
                <div className="grid grid-cols-3 gap-4">
                  {displayActions.map((item) => {
                    const IconComponent = getIconComponent(item.logo)
                    return (
                      <Button
                        key={`${item.pluginName}-${item.name}`}
                        variant="outline"
                        className="h-24 flex flex-col items-center justify-center text-center p-2"
                        onClick={() => onAddAction(item.name, item.pluginName, item.pluginName)}
                      >
                        <IconComponent className="h-8 w-8 mb-2" />
                        <span className="text-xs font-medium line-clamp-1">{item.pluginName}</span>
                        <span className="text-xs text-muted-foreground line-clamp-1">{item.name}</span>
                      </Button>
                    )
                  })}
                </div>
              </ScrollArea>
            </TabsContent>
          </Tabs>
        </div>
      </DialogContent>
    </Dialog>
  )
}

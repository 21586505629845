import React from 'react'
import { cn } from "../../lib/utils"

interface AvatarProps {
  src?: string;
  alt?: string;
  fallback: string;
  className?: string;
}

export const Avatar: React.FC<AvatarProps> = ({ src, alt, fallback, className = '' }) => {
  return (
    <div className={cn(
      "relative aspect-square rounded-full overflow-hidden bg-muted flex items-center justify-center",
      className
    )}>
      {src ? (
        <img 
          src={src} 
          alt={alt || 'Avatar'} 
          className="absolute w-full h-full object-cover"
        />
      ) : (
        <span className="text-sm font-medium text-muted-foreground">{fallback}</span>
      )}
    </div>
  )
}
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Plus, HelpCircle, ArrowLeft, ChevronLeft, X, Menu } from 'lucide-react'
import { motion } from 'framer-motion'
import { Button } from "./components/ui/button"
import { Input } from "./components/ui/input"
import { ThemeProvider } from "./components/ThemeProvider"
import { Sidebar } from './components/Sidebar'
import { NodeCard } from './components/NodeCard'
import { AddActionDialog } from './components/AddActionDialog'
import { useNodes } from './hooks/useNodes'
import { ModeToggle } from './components/ModeToggle'
import { TemplatesDialog } from './components/TemplatesDialog'
import { Dashboard } from './components/Dashboard'
import { Plugin } from './services/pluginService'
import { PluginBrowser } from './components/PluginBrowser'
import { BrowseIntegrationsDialog, Integration } from './components/BrowseIntegrationsDialog';
import { AddElgatoToolsDialog, ElgatoTool } from './components/AddElgatoToolsDialog';
import * as FaIcons from 'react-icons/fa'
import * as SiIcons from 'react-icons/si'
import * as LucideIcons from 'lucide-react';
import { NodeData, IconType } from './types/types';
import './custom.css'
import { Canvas } from './components/Canvas';
import { ProfileMenu } from './components/ProfileMenu'
import { Template } from './types/types'
import { ScrollArea, ScrollBar } from "./components/ui/scroll-area"
import * as MdIcons from 'react-icons/md';
import * as GiIcons from 'react-icons/gi';
import { DropResult } from 'react-beautiful-dnd';
import { cn } from './lib/utils';

interface Workflow {
  id: string;
  name: string;
  nodes: any[]; // Replace with your actual node type
  lastModified: string;
  isActive: boolean;
  status: 'active' | 'paused' | 'draft';
}

// Add this function before the App component
const getIconForPlugin = (pluginName: string): IconType => {
  const iconMap: { [key: string]: IconType } = {
    'OBS Studio': SiIcons.SiObsstudio,
    'Twitch': FaIcons.FaTwitch,
    'YouTube': FaIcons.FaYoutube,
    'Spotify': FaIcons.FaSpotify,
    'Discord': FaIcons.FaDiscord,
    // Add more mappings as needed
  };

  return iconMap[pluginName] || LucideIcons.HelpCircle;
};

// Add this function to get the appropriate icon for a node
const getIconForNode = (category: string): IconType | React.ElementType => {
  const categoryIconMap: { [key: string]: IconType | React.ElementType } = {
    'Actions': LucideIcons.Play,
    'Triggers': LucideIcons.Zap,
    // Add more mappings as needed
  };

  return categoryIconMap[category] || LucideIcons.HelpCircle;
};

function App() {
  const [workflowName, setWorkflowName] = useState('Untitled Workflow')
  const [isEditing, setIsEditing] = useState(false)
  const [isAddActionOpen, setIsAddActionOpen] = useState(false)
  const [editingNodeId, setEditingNodeId] = useState<string | null>(null)
  const [isTemplatesOpen, setIsTemplatesOpen] = useState(false)
  const [currentView, setCurrentView] = useState<'dashboard' | 'workflow'>('dashboard')
  const { nodes, setNodes, addNode, editNodeTitle, duplicateNode, deleteNode } = useNodes()
  const [workflows, setWorkflows] = useState<Workflow[]>([])
  const [currentWorkflowId, setCurrentWorkflowId] = useState<string | null>(null)
  const [isPluginBrowserOpen, setIsPluginBrowserOpen] = useState(false)
  const [isBrowseIntegrationsOpen, setIsBrowseIntegrationsOpen] = useState(false);
  const [isAddElgatoToolsOpen, setIsAddElgatoToolsOpen] = useState(false);
  const [totalTasksAutomated, setTotalTasksAutomated] = useState(0)
  const [timeFrame, setTimeFrame] = useState<'today' | 'this_week' | 'this_month' | 'all_time'>('all_time');
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false)

  useEffect(() => {
    const savedWorkflows = localStorage.getItem('workflows')
    if (savedWorkflows) {
      setWorkflows(JSON.parse(savedWorkflows))
    }
  }, [])

  const saveWorkflows = useCallback((updatedWorkflows: Workflow[]) => {
    setWorkflows(updatedWorkflows)
    localStorage.setItem('workflows', JSON.stringify(updatedWorkflows))
  }, [])

  const createNewWorkflow = () => {
    const newWorkflow: Workflow = {
      id: Date.now().toString(),
      name: 'Untitled Workflow',
      nodes: [],
      lastModified: new Date().toLocaleString(),
      isActive: false,
      status: 'draft'
    }

    saveWorkflows([...workflows, newWorkflow])
    setCurrentWorkflowId(newWorkflow.id)
    setWorkflowName(newWorkflow.name)
    setNodes([])
    setCurrentView('workflow')
  }

  const saveWorkflow = useCallback(() => {
    if (!currentWorkflowId) return

    const existingWorkflow = workflows.find(w => w.id === currentWorkflowId);
    if (!existingWorkflow) return;

    const hasChanged = existingWorkflow.name !== workflowName || 
                       JSON.stringify(existingWorkflow.nodes) !== JSON.stringify(nodes);

    const updatedWorkflow: Workflow = {
      ...existingWorkflow,
      name: workflowName,
      nodes: nodes,
      lastModified: hasChanged ? new Date().toLocaleString() : existingWorkflow.lastModified,
    }

    const updatedWorkflows = workflows.map(w => 
      w.id === currentWorkflowId ? updatedWorkflow : w
    )

    saveWorkflows(updatedWorkflows)
  }, [currentWorkflowId, workflowName, nodes, workflows, saveWorkflows])

  useEffect(() => {
    if (currentWorkflowId) {
      saveWorkflow()
    }
  }, [currentWorkflowId, nodes, workflowName, saveWorkflow])

  const openWorkflow = (workflowId: string) => {
    const workflow = workflows.find(w => w.id === workflowId)
    if (workflow) {
      setWorkflowName(workflow.name)
      setNodes(workflow.nodes)
      setCurrentWorkflowId(workflow.id)
      setCurrentView('workflow')
    }
  }

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, item: any, category: string) => {
    e.dataTransfer.setData('text/plain', JSON.stringify(item))
    e.dataTransfer.setData('text/category', category)
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    const itemData = e.dataTransfer.getData('text/plain')
    const category = e.dataTransfer.getData('text/category')
    const item = JSON.parse(itemData)
    
    let icon: IconType = HelpCircle as IconType; // Default icon
    if (item.logo) {
      const iconSet = item.logo.startsWith('Fa') ? FaIcons :
                      item.logo.startsWith('Si') ? SiIcons :
                      item.logo.startsWith('Md') ? MdIcons :
                      item.logo.startsWith('Gi') ? GiIcons : LucideIcons;
      
      const IconComponent = (iconSet as any)[item.logo];
      if (IconComponent) {
        icon = IconComponent as IconType;
      }
    }
    
    addNode({
      name: `${item.pluginName}:${item.name}`,
      category,
      icon,
    })
  }

  const handleAddAction = useCallback((action: string, category: string, pluginName: string) => {
    const newNode = {
      id: `node-${Date.now()}`,
      name: `${pluginName}:${action}`,
      category,
      icon: getIconForPlugin(pluginName),
    }
    addNode(newNode)
    setTotalTasksAutomated((prev: number) => prev + 1)
    setIsAddActionOpen(false)
  }, [addNode])

  const openPluginBrowser = (index: number) => {
    setIsPluginBrowserOpen(true)
  }

  const handleUseTemplate = (template: Template) => {
    console.log("Using template:", template);
    const newNodes: NodeData[] = template.nodes.map(node => ({
      id: node.id,
      name: node.title,
      category: node.category,
      icon: getIconForNode(node.category)
    }));

    // Create a new workflow from the template
    const newWorkflow: Workflow = {
      id: Date.now().toString(),
      name: template.name,
      nodes: newNodes,
      lastModified: new Date().toLocaleString(),
      isActive: true,
      status: 'active'
    };

    // Add the new workflow to the list of workflows
    saveWorkflows([...workflows, newWorkflow]);

    // Set the current workflow to the new one
    setCurrentWorkflowId(newWorkflow.id);
    setWorkflowName(newWorkflow.name);
    setNodes(newNodes);
    setCurrentView('workflow');
  };

  const deleteWorkflow = (workflowId: string) => {
    const updatedWorkflows = workflows.filter(w => w.id !== workflowId)
    saveWorkflows(updatedWorkflows)
    if (currentWorkflowId === workflowId) {
      setCurrentWorkflowId(null)
      setWorkflowName('Untitled Workflow')
      setNodes([])
      setCurrentView('dashboard')
    }
  }

  const duplicateWorkflow = (workflowId: string) => {
    const workflowToDuplicate = workflows.find(w => w.id === workflowId)
    if (workflowToDuplicate) {
      const newWorkflow: Workflow = {
        ...workflowToDuplicate,
        id: Date.now().toString(),
        name: `${workflowToDuplicate.name} (Copy)`,
        lastModified: new Date().toLocaleString(),
        status: 'draft'
      }
      saveWorkflows([...workflows, newWorkflow])
    }
  }

  const getWorkflowStatus = (workflow: Workflow): 'active' | 'paused' | 'draft' => {
    if (workflow.name !== 'Untitled Workflow' && workflow.nodes.length > 0) {
      return 'active';
    } else if (workflow.nodes.length === 1) {
      return 'paused';
    }
    return 'draft';
  }

  const toggleWorkflowStatus = (workflowId: string) => {
    const updatedWorkflows = workflows.map(w => {
      if (w.id === workflowId) {
        const newStatus = getWorkflowStatus(w);
        return { ...w, status: newStatus };
      }
      return w;
    });
    saveWorkflows(updatedWorkflows);
  }

  const onDragEnd = useCallback((result: DropResult) => {
    if (!result.destination) return;
    
    setNodes(prevNodes => {
      const newNodes = Array.from(prevNodes);
      const [reorderedItem] = newNodes.splice(result.source.index, 1);
      newNodes.splice(result.destination!.index, 0, reorderedItem);
      return newNodes;
    });
  }, [setNodes]);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleAddIntegration = () => {
    setIsPluginBrowserOpen(true)
  }

  const handleSelectAction = (plugin: Plugin, actionName: string) => {
    addNode({
      name: `${plugin.name}:${actionName}`,
      category: 'integration',
      icon: plugin.app_icon ? plugin.app_icon : (HelpCircle as IconType),
    });
    setIsPluginBrowserOpen(false);
  };

  const handleAddPlugin = () => {
    setIsPluginBrowserOpen(true);
  };

  const handleConnectService = () => {
    setIsBrowseIntegrationsOpen(true);
  };

  const handleAddElgatoTool = () => {
    setIsAddElgatoToolsOpen(true);
  };

  const handleSelectIntegration = (integration: Integration) => {
    const IconComponent = (FaIcons as any)[integration.iconName] || (SiIcons as any)[integration.iconName];
    addNode({
      name: `${integration.name}:Default`,
      category: 'integration',
      icon: IconComponent,
    });
    setIsBrowseIntegrationsOpen(false);
  };

  const handleSelectElgatoTool = (tool: ElgatoTool) => {
    addNode({
      name: tool.name,
      category: 'Elgato Tools',
      icon: tool.logo, // Use the logo property here
    });
    setIsAddElgatoToolsOpen(false);
  };

  const handleAddNode = useCallback((nodeOrIndex: NodeData | number) => {
    if (typeof nodeOrIndex === 'number') {
      setIsPluginBrowserOpen(true);
    } else {
      const newNode: NodeData = {
        ...nodeOrIndex,
        icon: typeof nodeOrIndex.icon === 'string' 
          ? (FaIcons as any)[nodeOrIndex.icon] || (SiIcons as any)[nodeOrIndex.icon] || nodeOrIndex.icon
          : nodeOrIndex.icon
      };
      setNodes(prevNodes => [...prevNodes, newNode]);
      setTotalTasksAutomated((prev: number) => prev + 1);
    }
  }, [setNodes]);

  // Calculate total tasks automated across all workflows
  useEffect(() => {
    const totalTasks = workflows.reduce((sum, workflow) => sum + workflow.nodes.length, 0);
    setTotalTasksAutomated(totalTasks);
  }, [workflows]);

  // Calculate estimated time saved
  const estimatedTimeSaved = useMemo(() => {
    return totalTasksAutomated * 3; // Assuming each task saves 3 minutes
  }, [totalTasksAutomated]);

  const timeSavedPercentage = useMemo(() => {
    const workdayMinutes = 8 * 60; // Assuming 8-hour workday
    return Math.min((estimatedTimeSaved / workdayMinutes) * 100, 100);
  }, [estimatedTimeSaved]);

  return (
    <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
      <div className="flex h-screen bg-background text-foreground">
        <Sidebar 
          onDragStart={handleDragStart}
          setTemplatesOpen={setIsTemplatesOpen}
          setCurrentView={setCurrentView}
          currentView={currentView}
          estimatedTimeSaved={estimatedTimeSaved}
          timeSavedPercentage={timeSavedPercentage}
          totalTasksAutomated={totalTasksAutomated}
          timeFrame={timeFrame}
          onAddPlugin={handleAddPlugin}
          onBrowseIntegrations={handleConnectService}
          onAddElgatoTools={handleAddElgatoTool}
          isCollapsed={isSidebarCollapsed}
          setIsCollapsed={setIsSidebarCollapsed}
        />
        <div className="flex-1 flex flex-col overflow-hidden">
          {/* Header area */}
          <div className="border-b border-border p-4 flex justify-between items-center h-[65px] pl-16 lg:pl-4">
            <div className="flex items-center space-x-4">
              <Button
                variant="ghost"
                size="icon"
                className="hidden lg:flex"
                onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
              >
                <ChevronLeft className={cn(
                  "h-4 w-4 transition-transform duration-200",
                  isSidebarCollapsed && "rotate-180"
                )} />
              </Button>

              {currentView === 'workflow' ? (
                <>
                  {isEditing ? (
                    <Input
                      type="text"
                      value={workflowName}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setWorkflowName(e.target.value)}
                      onBlur={() => {
                        setIsEditing(false)
                        saveWorkflow()
                      }}
                      onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === 'Enter') {
                          setIsEditing(false)
                          saveWorkflow()
                        }
                      }}
                      className="text-2xl font-semibold bg-transparent border-none focus:ring-0 text-foreground w-64 sm:w-80 md:w-96"
                      autoFocus
                    />
                  ) : (
                    <h1
                      className="text-2xl font-semibold text-foreground cursor-pointer"
                      onClick={() => setIsEditing(true)}
                    >
                      {workflowName}
                    </h1>
                  )}
                  <div 
                    className={`w-3 h-3 rounded-full ${
                      (() => {
                        const currentWorkflow = workflows.find(w => w.id === currentWorkflowId);
                        if (currentWorkflow) {
                          if (currentWorkflow.name !== 'Untitled Workflow' && currentWorkflow.nodes.length > 0) {
                            return 'bg-green-500';
                          } else if (currentWorkflow.nodes.length === 1) {
                            return 'bg-yellow-500';
                          }
                        }
                        return 'bg-gray-500';
                      })()
                    }`}
                    onClick={() => currentWorkflowId && toggleWorkflowStatus(currentWorkflowId)}
                  />
                </>
              ) : (
                <h1 className="text-2xl font-semibold">Dashboard</h1>
              )}
            </div>
            <div className="flex items-center space-x-4">
              {currentView === 'workflow' ? (
                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => setCurrentView('dashboard')}
                  className="sm:w-auto sm:px-3"
                >
                  <ArrowLeft className="h-4 w-4 sm:mr-2" />
                  <span className="hidden sm:inline">Back to Dashboard</span>
                </Button>
              ) : (
                <Button
                  variant="outline"
                  size="icon"
                  onClick={createNewWorkflow}
                  className="sm:w-auto sm:px-3"
                >
                  <Plus className="h-4 w-4 sm:mr-2" />
                  <span className="hidden sm:inline">Create Workflow</span>
                </Button>
              )}
              <ModeToggle />
            </div>
          </div>
          <div className="flex-1 overflow-hidden">
            {currentView === 'dashboard' ? (
              <ScrollArea className="h-full" type="always">
                <Dashboard 
                  workflows={workflows} 
                  openWorkflow={openWorkflow}
                  deleteWorkflow={deleteWorkflow}
                  duplicateWorkflow={duplicateWorkflow}
                  toggleWorkflowStatus={toggleWorkflowStatus}
                  createNewWorkflow={createNewWorkflow}
                  onUseTemplate={handleUseTemplate}
                />
                <ScrollBar orientation="vertical" className="!w-2 !right-1" />
              </ScrollArea>
            ) : (
              <div className="h-full w-full bg-background">
                <Canvas
                  nodes={nodes}
                  onDragEnd={onDragEnd}
                  onEditNode={(id) => setEditingNodeId(id === editingNodeId ? null : id)}
                  onTitleChange={editNodeTitle}
                  onDuplicateNode={duplicateNode}
                  onDeleteNode={deleteNode}
                  onAddPlugin={handleAddPlugin}
                  onBrowseIntegrations={handleConnectService}
                  onAddElgatoTools={handleAddElgatoTool}
                  onAddNode={handleAddNode}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <AddActionDialog
        isOpen={isAddActionOpen}
        onOpenChange={setIsAddActionOpen}
        onAddAction={handleAddAction}
      />
      <TemplatesDialog
        isOpen={isTemplatesOpen}
        onOpenChange={setIsTemplatesOpen}
        onUseTemplate={handleUseTemplate}
      />
      <PluginBrowser
        onSelectAction={handleSelectAction}
        isOpen={isPluginBrowserOpen}
        onClose={() => setIsPluginBrowserOpen(false)}
      />
      <BrowseIntegrationsDialog
        isOpen={isBrowseIntegrationsOpen}
        onClose={() => setIsBrowseIntegrationsOpen(false)}
        onSelectIntegration={handleSelectIntegration}
      />
      <AddElgatoToolsDialog
        isOpen={isAddElgatoToolsOpen}
        onClose={() => setIsAddElgatoToolsOpen(false)}
        onSelectTool={handleSelectElgatoTool}
      />
    </ThemeProvider>
  )
}

export default App

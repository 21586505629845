import React from 'react';
import { Card, CardHeader, CardTitle } from './ui/card';
import { Button } from "./ui/button";
import { MoreHorizontal } from 'lucide-react';
import { NodeData, IconType } from '../types/types';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "./ui/dropdown-menu";
import * as LucideIcons from 'lucide-react';

interface NodeCardProps {
  node: NodeData;
  onEdit: () => void;
  onTitleChange: (newTitle: string) => void;
  onDuplicate: () => void;
  onDelete: () => void;
}

export function NodeCard({ node, onEdit, onTitleChange, onDuplicate, onDelete }: NodeCardProps) {
  const renderIcon = () => {
    if (typeof node.icon === 'string') {
      if (node.icon in LucideIcons) {
        const LucideIcon = LucideIcons[node.icon as keyof typeof LucideIcons] as React.ElementType;
        return <LucideIcon className="w-5 h-5" />;
      }
      // Handle Elgato tool icons
      if (node.icon in LucideIcons) {
        const ElgatoIcon = LucideIcons[node.icon as keyof typeof LucideIcons] as React.ElementType;
        return <ElgatoIcon className="w-5 h-5" />;
      }
      return (
        <img
          src={node.icon}
          alt={`${node.name} icon`}
          className="w-5 h-5 object-contain"
        />
      );
    } else if (typeof node.icon === 'function') {
      const IconComponent = node.icon as IconType;
      return <IconComponent className="w-5 h-5" />;
    }
    return null;
  };

  const [pluginName, actionName] = node.name.split(':');

  return (
    <Card className="mb-2 w-80 bg-background shadow-sm">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 py-3 px-4">
        <div className="flex items-center space-x-2">
          {renderIcon()}
          <CardTitle className="text-sm font-medium">
            <div className="flex flex-col">
              <span className="text-xs text-muted-foreground">{actionName || node.category}</span>
              <span className="text-sm text-foreground">{actionName ? pluginName : node.name}</span>
            </div>
          </CardTitle>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={onEdit}>Edit</DropdownMenuItem>
            <DropdownMenuItem onClick={onDuplicate}>Duplicate</DropdownMenuItem>
            <DropdownMenuItem onClick={onDelete}>Delete</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </CardHeader>
    </Card>
  );
}

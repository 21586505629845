import { useState, useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { NodeData, IconType } from '../types/types'

export function useNodes() {
  const [nodes, setNodes] = useState<NodeData[]>([])

  const addNode = useCallback((nodeData: Omit<NodeData, 'id'>) => {
    const newNode: NodeData = {
      ...nodeData,
      id: uuidv4(),
    };
    setNodes((prevNodes) => [...prevNodes, newNode]);
  }, []);

  const editNodeTitle = (id: string, newTitle: string) => {
    setNodes(prevNodes => prevNodes.map(node => node.id === id ? { ...node, title: newTitle } : node))
  }

  const duplicateNode = (index: number) => {
    setNodes(prevNodes => {
      const newNode = { ...prevNodes[index], id: uuidv4() }
      return [...prevNodes.slice(0, index + 1), newNode, ...prevNodes.slice(index + 1)]
    })
  }

  const deleteNode = (index: number) => {
    setNodes(prevNodes => prevNodes.filter((_, i) => i !== index))
  }

  return { nodes, setNodes, addNode, editNodeTitle, duplicateNode, deleteNode }
}

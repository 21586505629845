import React, { useState, useEffect } from 'react'
import { Input } from "./ui/input"
import { Button } from "./ui/button"
import { ScrollArea } from "./ui/scroll-area"
import { Search, Layout, FileText, PlugIcon, Settings, ChevronRight, Info, Menu, X, ChevronLeft } from 'lucide-react'
import { ElgatoLogo } from './ElgatoLogo'
import { FeedbackModal } from './FeedbackModal'
import { Progress } from "./ui/progress"
import HyperText from './ui/hyper-text'
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from "./ui/collapsible"
import { fetchPlugins, Plugin } from '../services/pluginService'
import * as Icons from 'react-icons/fa'
import * as SiIcons from 'react-icons/si'
import * as LucideIcons from 'lucide-react'
import { categories } from '../data/categories'
import { Category, ActionItem, ActionCategory } from '../types/types'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./ui/tooltip"
import { cn } from "../lib/utils"
import { Nav } from "./ui/nav"
import { Separator } from "./ui/separator"
import { ProfileMenu } from './ProfileMenu'

interface SidebarProps {
  setTemplatesOpen: React.Dispatch<React.SetStateAction<boolean>>
  setCurrentView: React.Dispatch<React.SetStateAction<'dashboard' | 'workflow'>>
  currentView: 'dashboard' | 'workflow'
  estimatedTimeSaved: number
  timeSavedPercentage: number
  onAddPlugin: () => void
  onBrowseIntegrations: () => void
  onAddElgatoTools: () => void
  onDragStart: (e: React.DragEvent<HTMLDivElement>, item: any, category: string) => void
  totalTasksAutomated: number
  timeFrame: 'today' | 'this_week' | 'this_month' | 'all_time'
  isCollapsed?: boolean
  setIsCollapsed?: (collapsed: boolean) => void
}

export function Sidebar({ 
  setTemplatesOpen, 
  setCurrentView, 
  currentView, 
  estimatedTimeSaved,
  timeSavedPercentage,
  onAddPlugin,
  onBrowseIntegrations,
  onAddElgatoTools,
  onDragStart,
  totalTasksAutomated,
  timeFrame,
  isCollapsed = false,
  setIsCollapsed = () => {},
}: SidebarProps) {
  const [triggerHyperText, setTriggerHyperText] = useState(0)
  const [openCategories, setOpenCategories] = useState<string[]>([])
  const [plugins, setPlugins] = useState<Plugin[]>([])
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    setTriggerHyperText(prev => prev + 1)
    const intervalId = setInterval(() => {
      setTriggerHyperText(prev => prev + 1)
    }, 30000)
    return () => clearInterval(intervalId)
  }, [])

  const toggleCategory = (category: string) => {
    setOpenCategories(prev => 
      prev.includes(category)
        ? prev.filter(cat => cat !== category)
        : [...prev, category]
    )
  }

  const getItemsForCategory = (categoryName: string): ActionItem[] => {
    return categories.find((category: Category) => category.name === categoryName)?.items || [];
  }

  const getIconComponent = (iconName: string) => {
    if (iconName.startsWith('Fa')) return (Icons as any)[iconName];
    if (iconName.startsWith('Si')) return (SiIcons as any)[iconName];
    return (LucideIcons as any)[iconName] || Icons.FaQuestionCircle;
  }

  const actionCategories: ActionCategory[] = [
    {
      name: 'Plugins',
      icon: PlugIcon,
      items: plugins.map(plugin => ({
        name: plugin.name,
        pluginName: plugin.name,
        logo: plugin.app_icon || 'FaQuestionCircle'
      }))
    },
    {
      name: 'Integrations',
      icon: Search,
      items: getItemsForCategory('Integrations')
    },
    {
      name: 'Elgato Tools',
      icon: Settings,
      items: getItemsForCategory('Elgato Tools')
    }
  ]

  useEffect(() => {
    const loadPlugins = async () => {
      try {
        const response = await fetchPlugins({ page: 1, limit: 20, sortBy: 'download_count', sortOrder: 'desc' })
        setPlugins(response.results)
      } catch (error) {
        console.error('Error fetching plugins:', error)
      }
    }
    loadPlugins()
  }, [])

  const getTimeFrameText = (timeFrame: string) => {
    switch (timeFrame) {
      case 'today': return 'Today';
      case 'this_week': return 'This Week';
      case 'this_month': return 'This Month';
      case 'all_time': return 'All Time';
      default: return '';
    }
  }

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  const handleCategoryClick = (category: string) => {
    if (isCollapsed) {
      setIsCollapsed(false);
      setOpenCategories(prev => [...prev, category]);
    } else {
      toggleCategory(category);
    }
  };

  const filterItems = (items: ActionItem[]) => {
    if (!searchTerm) return items;
    return items.filter(item => 
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.pluginName?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  return (
    <>
      {/* Mobile menu button */}
      <div className="lg:hidden fixed top-0 left-0 z-50 p-4">
        <Button variant="ghost" size="icon" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
        </Button>
      </div>

      {/* Updated sidebar structure */}
      <div className={cn(
        "fixed inset-y-0 left-0 z-40 flex h-screen flex-col bg-background border-r border-border",
        "transition-all duration-300 ease-in-out lg:relative lg:translate-x-0",
        isMobileMenuOpen ? "translate-x-0" : "-translate-x-full lg:translate-x-0",
        isCollapsed ? "w-16" : "w-64"
      )}>
        {/* Logo header */}
        <div className={cn(
          "p-4 flex items-center border-b border-border h-[65px]",
          isCollapsed ? "justify-center px-2" : "space-x-3"
        )}>
          <ElgatoLogo />
          {!isCollapsed && (
            <HyperText
              text="AUTOMATE"
              className="text-xl font-bold leading-none tracking-wide uppercase text-primary"
              duration={3000}
              animateOnLoad={true}
              key={triggerHyperText}
            />
          )}
        </div>

        <div className="flex-1 flex flex-col overflow-hidden">
          {/* Main navigation */}
          <Nav
            isCollapsed={isCollapsed}
            links={[
              {
                title: "Dashboard",
                icon: Layout,
                variant: currentView === 'dashboard' ? 'default' : 'ghost',
                onClick: () => {
                  setCurrentView('dashboard')
                  setIsMobileMenuOpen(false)
                }
              },
              {
                title: "Templates",
                icon: FileText,
                variant: 'ghost',
                onClick: () => {
                  setTemplatesOpen(true)
                  setIsMobileMenuOpen(false)
                }
              }
            ]}
          />

          {/* Adjusted feedback button to match Nav styling */}
          {!isCollapsed && (
            <div className="px-2">
              <FeedbackModal />
            </div>
          )}
          
          <Separator className="my-4" />

          {currentView === 'workflow' && (
            <>
              {!isCollapsed && (
                <>
                  <div className="px-4 py-2">
                    <h2 className="text-sm font-semibold mb-2">
                      Available Actions
                    </h2>
                  </div>
                  <div className="px-4 mb-2">
                    <Input
                      placeholder="Search actions..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="h-8"
                    />
                  </div>
                </>
              )}
              <ScrollArea className="flex-1">
                <div className="space-y-2">
                  {actionCategories.map((category) => {
                    const filteredItems = filterItems(category.items);
                    if (searchTerm && filteredItems.length === 0) return null;

                    return (
                      <Collapsible
                        key={category.name}
                        open={!isCollapsed && (openCategories.includes(category.name) || searchTerm.length > 0)}
                        onOpenChange={() => handleCategoryClick(category.name)}
                      >
                        <CollapsibleTrigger asChild>
                          <Button 
                            variant="ghost" 
                            className={cn(
                              "w-full",
                              isCollapsed && "h-9 w-9 p-0 mx-auto flex justify-center"
                            )}
                          >
                            <category.icon className="h-4 w-4" />
                            {!isCollapsed && (
                              <>
                                <span className="ml-2 flex-1 text-left">{category.name}</span>
                                <ChevronRight className={cn(
                                  "h-4 w-4 transition-transform duration-200",
                                  openCategories.includes(category.name) && "rotate-90"
                                )} />
                              </>
                            )}
                          </Button>
                        </CollapsibleTrigger>
                        <CollapsibleContent className={cn(
                          "space-y-1",
                          isCollapsed ? "flex flex-col items-center" : "pl-4"
                        )}>
                          {filteredItems.map((item: ActionItem) => {
                            const IconComponent = item.logo.startsWith('http') 
                              ? () => <img src={item.logo} alt={item.name} className="h-5 w-5" />
                              : getIconComponent(item.logo);
                            return (
                              <div
                                key={item.name}
                                className={cn(
                                  "cursor-move hover:bg-accent rounded-md",
                                  isCollapsed ? "w-9 h-9 flex items-center justify-center" : "p-2"
                                )}
                                draggable
                                onDragStart={(e) => onDragStart(e, item, category.name)}
                              >
                                <div className={cn(
                                  "flex items-center",
                                  isCollapsed ? "justify-center" : "w-full"
                                )}>
                                  <IconComponent className="h-5 w-5" />
                                  {!isCollapsed && (
                                    <span className="text-sm truncate ml-3 flex-1" title={item.name}>
                                      {item.name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </CollapsibleContent>
                      </Collapsible>
                    );
                  })}
                </div>
              </ScrollArea>
            </>
          )}
        </div>

        {/* Updated Profile Section */}
        <div className={cn(
          "border-t border-border",
          isCollapsed ? "p-2" : "p-4"
        )}>
          <ProfileMenu isCollapsed={isCollapsed} />
        </div>
      </div>

      {/* Mobile overlay */}
      {isMobileMenuOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden"
          onClick={toggleMobileMenu}
        />
      )}
    </>
  )
}

import React, { useState } from 'react'
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "../components/ui/card"
import { Button } from "../components/ui/button"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table"
import { Trash2, Copy, Zap, Globe, Cpu, PlusCircle, Clock, BarChart2, PieChart, ArrowRight, Info, ChevronLeft, ChevronRight } from 'lucide-react'
import { Workflow, Template, NodeData } from '../types/types'
import { BarChart, Bar, XAxis, YAxis, Tooltip as RechartsTooltip, ResponsiveContainer } from 'recharts'
import { cn } from "../lib/utils"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../components/ui/tooltip"
import { motion, AnimatePresence } from 'framer-motion'
import { ScrollArea, ScrollBar } from "../components/ui/scroll-area"
import { NumberTicker } from "./ui/number-ticker"

// Import templates from TemplatesDialog
import { templates } from './TemplatesDialog'

interface DashboardProps {
  workflows: Workflow[]
  openWorkflow: (id: string) => void
  deleteWorkflow: (id: string) => void
  duplicateWorkflow: (id: string) => void
  toggleWorkflowStatus: (id: string) => void
  createNewWorkflow: () => void
  onUseTemplate: (template: Template) => void
}

// More convincing demo data
const automationData = [
  { name: 'Mon', tasks: 47 },
  { name: 'Tue', tasks: 52 },
  { name: 'Wed', tasks: 63 },
  { name: 'Thu', tasks: 55 },
  { name: 'Fri', tasks: 59 },
  { name: 'Sat', tasks: 28 },
  { name: 'Sun', tasks: 31 },
];

export function Dashboard({ workflows, openWorkflow, deleteWorkflow, duplicateWorkflow, toggleWorkflowStatus, createNewWorkflow, onUseTemplate }: DashboardProps) {
  const [templateIndex, setTemplateIndex] = useState(0)
  const totalTasksAutomated = automationData.reduce((sum, day) => sum + day.tasks, 0);
  const estimatedTimeSaved = totalTasksAutomated * 3; // Assuming each task saves 3 minutes
  const activeWorkflows = workflows.filter(w => w.status === 'active').length;
  const efficiencyGain = Math.round((estimatedTimeSaved / (40 * 60)) * 100);

  const nextTemplates = () => {
    setTemplateIndex((prevIndex) => (prevIndex + 4) % templates.length)
  }

  const prevTemplates = () => {
    setTemplateIndex((prevIndex) => (prevIndex - 4 + templates.length) % templates.length)
  }

  return (
    <ScrollArea className="h-full" type="always">
      <div className="flex flex-col p-6 space-y-6">
        <Card className="bg-gradient-to-r from-[#00A3E1] to-[#0078D4] text-white relative overflow-hidden flex-shrink-0">
          <div className="absolute inset-0 bg-grid-white/[0.2] bg-[size:16px_16px]" />
          <CardHeader>
            <CardTitle className="text-3xl font-bold relative z-10">Stream Deck Automate Advantage</CardTitle>
            <CardDescription className="text-white text-opacity-80 relative z-10">Bridging the gap between web-based and local automations</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 relative z-10">
              <div className="flex items-center space-x-2">
                <div className="bg-white bg-opacity-20 p-2 rounded-full">
                  <Zap className="h-6 w-6" />
                </div>
                <div>
                  <h3 className="font-semibold">Powerful Automation</h3>
                  <p className="text-sm text-white text-opacity-80">Create complex workflows with ease</p>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <div className="bg-white bg-opacity-20 p-2 rounded-full">
                  <Globe className="h-6 w-6" />
                </div>
                <div>
                  <h3 className="font-semibold">Web Integration</h3>
                  <p className="text-sm text-white text-opacity-80">Connect with your favorite online services</p>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <div className="bg-white bg-opacity-20 p-2 rounded-full">
                  <Cpu className="h-6 w-6" />
                </div>
                <div>
                  <h3 className="font-semibold">Local Control</h3>
                  <p className="text-sm text-white text-opacity-80">Harness the power of your Stream Deck</p>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card className="flex-grow">
          {workflows.length === 0 ? (
            <CardContent>
              <div className="flex flex-col items-center justify-center py-16">
                <div className="bg-muted rounded-full p-3 mb-4">
                  <PlusCircle className="h-6 w-6 text-muted-foreground" />
                </div>
                <h3 className="text-lg font-semibold mb-2">No workflows</h3>
                <p className="text-sm text-muted-foreground mb-6">Get started by creating a new workflow.</p>
                <Button onClick={createNewWorkflow}>
                  <PlusCircle className="mr-2 h-4 w-4" />
                  Create Workflow
                </Button>
              </div>
            </CardContent>
          ) : (
            <>
              <CardHeader>
                <CardTitle>Your Workflows</CardTitle>
                <CardDescription>Manage and monitor your automation workflows</CardDescription>
              </CardHeader>
              <CardContent>
                <ScrollArea className="h-[300px] pr-4" type="always">
                  <div className="pr-4">
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead className="w-[40%]">Name</TableHead>
                          <TableHead className="w-[30%]">Last Modified</TableHead>
                          <TableHead className="w-[10%] text-center">Status</TableHead>
                          <TableHead className="w-[20%]">Actions</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {workflows.map((workflow) => (
                          <TableRow 
                            key={workflow.id} 
                            className="cursor-pointer hover:bg-muted/50"
                            onClick={() => openWorkflow(workflow.id)}
                          >
                            <TableCell className="font-medium">{workflow.name}</TableCell>
                            <TableCell>{workflow.lastModified}</TableCell>
                            <TableCell>
                              <div className="flex justify-center">
                                <div 
                                  className={`w-3 h-3 rounded-full ${
                                    workflow.status === 'active' ? 'bg-green-500' : 
                                    workflow.status === 'paused' ? 'bg-yellow-500' : 'bg-gray-500'
                                  }`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toggleWorkflowStatus(workflow.id);
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="flex space-x-2" onClick={(e) => e.stopPropagation()}>
                                <Button variant="outline" size="sm" onClick={() => duplicateWorkflow(workflow.id)}>
                                  <Copy className="h-4 w-4" />
                                </Button>
                                <Button variant="outline" size="sm" onClick={() => deleteWorkflow(workflow.id)}>
                                  <Trash2 className="h-4 w-4" />
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                  <ScrollBar orientation="vertical" className="!w-2 !right-1" />
                </ScrollArea>
              </CardContent>
            </>
          )}
        </Card>

        <Card className="flex-shrink-0">
          <CardHeader className="flex flex-row items-center justify-between pb-2">
            <div>
              <CardTitle className="text-lg">Templates</CardTitle>
              <CardDescription className="text-sm">Get started quickly with pre-built workflow templates</CardDescription>
            </div>
            <div className="flex space-x-2">
              <Button variant="outline" size="sm" onClick={prevTemplates}>
                <ChevronLeft className="h-4 w-4" />
              </Button>
              <Button variant="outline" size="sm" onClick={nextTemplates}>
                <ChevronRight className="h-4 w-4" />
              </Button>
            </div>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {templates.slice(templateIndex, templateIndex + 4).map((template) => (
                <Card key={template.id} className="flex flex-col h-full">
                  <CardHeader className="p-4 pb-2">
                    <div className="flex items-center justify-between mb-2">
                      <CardTitle className="text-sm font-medium line-clamp-1 flex-grow mr-2">{template.name}</CardTitle>
                      <div className="text-xs px-2 py-1 rounded-full bg-secondary text-secondary-foreground whitespace-nowrap">
                        {template.category}
                      </div>
                    </div>
                    <CardDescription className="text-xs line-clamp-2">{template.description}</CardDescription>
                  </CardHeader>
                  <CardContent className="p-4 pt-0 flex-grow flex flex-col justify-end">
                    <Button 
                      size="sm"
                      className="w-fit px-4 mt-2" 
                      onClick={() => onUseTemplate(template)}
                    >
                      Use Template
                    </Button>
                  </CardContent>
                </Card>
              ))}
            </div>
          </CardContent>
        </Card>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 flex-shrink-0">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <BarChart2 className="w-6 h-6 mr-2" />
                Automation Activity
              </CardTitle>
              <CardDescription>Tasks automated over the past week</CardDescription>
            </CardHeader>
            <CardContent>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart data={automationData}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <RechartsTooltip 
                    contentStyle={{ backgroundColor: '#0078D4', color: '#ffffff', border: 'none', borderRadius: '8px' }}
                    labelStyle={{ color: '#ffffff' }}
                  />
                  <Bar dataKey="tasks" fill="#00A3E1" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <PieChart className="w-6 h-6 mr-2" />
                Automation Stats
              </CardTitle>
              <CardDescription>Key metrics for your automated workflows</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-2 gap-4">
                <div className="bg-secondary p-4 rounded-lg">
                  <div className="text-2xl font-bold">
                    <NumberTicker value={workflows.length} delay={500} />
                  </div>
                  <p className="text-xs text-muted-foreground">Total Workflows</p>
                </div>
                <div className="bg-secondary p-4 rounded-lg">
                  <div className="text-2xl font-bold">
                    <NumberTicker value={activeWorkflows} delay={700} />
                  </div>
                  <p className="text-xs text-muted-foreground">Active Workflows</p>
                </div>
                <div className="bg-secondary p-4 rounded-lg">
                  <div className="text-2xl font-bold">
                    <NumberTicker value={totalTasksAutomated} delay={900} />
                  </div>
                  <p className="text-xs text-muted-foreground">Tasks Automated</p>
                </div>
                <div className="bg-secondary p-4 rounded-lg">
                  <div className="text-2xl font-bold flex items-center">
                    <NumberTicker value={efficiencyGain} delay={1100} />%
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Info className="h-4 w-4 ml-2 text-muted-foreground cursor-help" />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Efficiency Gain represents the percentage of time saved through automation relative to the total tasks performed. It's calculated by dividing the time saved by the total time it would have taken to perform all tasks manually.</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                  <p className="text-xs text-muted-foreground">Efficiency Gain</p>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
      <ScrollBar orientation="vertical" className="!w-2 !right-1" />
    </ScrollArea>
  )
}

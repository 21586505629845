import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from './ui/dialog';
import { Button } from './ui/button';
import { ScrollArea } from './ui/scroll-area';
import { FaGoogle, FaSlack, FaDropbox, FaTwitter, FaGithub, FaTrello, FaMailchimp, FaShopify, FaHubspot, FaVideo, FaStripe } from 'react-icons/fa';
import { SiAirtable, SiZapier, SiMicrosoftteams, SiAsana } from 'react-icons/si';
import { IconType } from 'react-icons';

export interface Integration {
  id: string;
  name: string;
  description: string;
  iconName: string;
}

const integrationIcons: { [key: string]: IconType } = {
  FaGoogle, FaSlack, FaDropbox, FaTwitter, FaGithub, FaTrello, FaMailchimp, FaShopify, FaHubspot, FaVideo, FaStripe,
  SiAirtable, SiZapier, SiMicrosoftteams, SiAsana
};

const integrations: Integration[] = [
  { id: '1', name: 'Google Workspace', description: 'Integrate with Gmail, Google Drive, and more', iconName: 'FaGoogle' },
  { id: '2', name: 'Slack', description: 'Connect and automate Slack messaging', iconName: 'FaSlack' },
  { id: '3', name: 'Dropbox', description: 'Manage and automate file storage', iconName: 'FaDropbox' },
  { id: '4', name: 'Twitter', description: 'Automate tweets and monitor mentions', iconName: 'FaTwitter' },
  { id: '5', name: 'GitHub', description: 'Streamline your development workflow', iconName: 'FaGithub' },
  { id: '6', name: 'Trello', description: 'Automate your Trello boards and cards', iconName: 'FaTrello' },
  { id: '7', name: 'Mailchimp', description: 'Automate email marketing campaigns', iconName: 'FaMailchimp' },
  { id: '8', name: 'Shopify', description: 'Streamline your e-commerce operations', iconName: 'FaShopify' },
  { id: '9', name: 'HubSpot', description: 'Automate your CRM and marketing', iconName: 'FaHubspot' },
  { id: '10', name: 'Asana', description: 'Streamline project management tasks', iconName: 'SiAsana' },
  { id: '11', name: 'Zoom', description: 'Automate video conferencing tasks', iconName: 'FaVideo' },
  { id: '12', name: 'Stripe', description: 'Automate payment processing', iconName: 'FaStripe' },
  { id: '13', name: 'Airtable', description: 'Connect and automate databases', iconName: 'SiAirtable' },
  { id: '14', name: 'Zapier', description: 'Connect with thousands of apps', iconName: 'SiZapier' },
  { id: '15', name: 'Microsoft Teams', description: 'Streamline team communication', iconName: 'SiMicrosoftteams' },
];

interface BrowseIntegrationsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectIntegration: (integration: Integration) => void;
}

export function BrowseIntegrationsDialog({ isOpen, onClose, onSelectIntegration }: BrowseIntegrationsDialogProps) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Browse Integrations</DialogTitle>
        </DialogHeader>
        <ScrollArea className="mt-2 max-h-[60vh] pr-4">
          {integrations.map((integration) => {
            const IconComponent = integrationIcons[integration.iconName];
            return (
              <div key={integration.id} className="flex items-center space-x-4 mb-4">
                <div className="text-primary">
                  {IconComponent && <IconComponent className="h-6 w-6" />}
                </div>
                <div className="flex-grow">
                  <h3 className="font-semibold">{integration.name}</h3>
                  <p className="text-sm text-muted-foreground">{integration.description}</p>
                </div>
                <Button onClick={() => onSelectIntegration(integration)}>Add</Button>
              </div>
            );
          })}
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}

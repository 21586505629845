import { Category } from '../types/types'
import { FaSpotify, FaDiscord, FaYoutube, FaTwitter, FaTwitch, FaSlack, FaDropbox, FaGithub, FaTrello, FaMailchimp, FaShopify, FaHubspot, FaVideo, FaStripe, FaGoogle } from 'react-icons/fa'
import { SiObsstudio, SiElgato, SiAirtable, SiZapier, SiMicrosoftteams, SiAsana, SiGoogle } from 'react-icons/si'
import { Settings, GitBranch, Clock, Webhook, Bell, FileInput, Terminal, RepeatIcon, FileText, Save, Puzzle, GitFork, Zap } from 'lucide-react'

export const categories: Category[] = [
  {
    name: "Plugins",
    icon: SiObsstudio,
    items: [
      { name: "OBS Studio", pluginName: "OBS Studio", logo: "SiObsstudio" },
      { name: "Twitch", pluginName: "Twitch", logo: "FaTwitch" },
      { name: "YouTube", pluginName: "YouTube", logo: "FaYoutube" },
      { name: "Spotify", pluginName: "Spotify", logo: "FaSpotify" },
      { name: "Discord", pluginName: "Discord", logo: "FaDiscord" },
      // Add more plugins here as needed
    ]
  },
  {
    name: "Integrations",
    icon: FaGoogle,
    items: [
      { name: "Google Workspace", pluginName: "Google Workspace", logo: "FaGoogle" },
      { name: "Slack", pluginName: "Slack", logo: "FaSlack" },
      { name: "Dropbox", pluginName: "Dropbox", logo: "FaDropbox" },
      { name: "Twitter", pluginName: "Twitter", logo: "FaTwitter" },
      { name: "GitHub", pluginName: "GitHub", logo: "FaGithub" },
      { name: "Trello", pluginName: "Trello", logo: "FaTrello" },
      { name: "Mailchimp", pluginName: "Mailchimp", logo: "FaMailchimp" },
      { name: "Shopify", pluginName: "Shopify", logo: "FaShopify" },
      { name: "HubSpot", pluginName: "HubSpot", logo: "FaHubspot" },
      { name: "Asana", pluginName: "Asana", logo: "SiAsana" },
      { name: "Zoom", pluginName: "Zoom", logo: "FaVideo" },
      { name: "Stripe", pluginName: "Stripe", logo: "FaStripe" },
      { name: "Airtable", pluginName: "Airtable", logo: "SiAirtable" },
      { name: "Zapier", pluginName: "Zapier", logo: "SiZapier" },
      { name: "Microsoft Teams", pluginName: "Microsoft Teams", logo: "SiMicrosoftteams" },
    ]
  },
  {
    name: "Elgato Tools",
    icon: SiElgato,
    items: [
      { name: "System Action", pluginName: "System Action", logo: "Settings" },
      { name: "Conditional Action", pluginName: "Conditional Action", logo: "GitBranch" },
      { name: "Trigger", pluginName: "Trigger", logo: "Zap" },
      { name: "Delay/Wait", pluginName: "Delay/Wait", logo: "Clock" },
      { name: "Webhook/API Call", pluginName: "Webhook/API Call", logo: "Webhook" },
      { name: "Notification", pluginName: "Notification", logo: "Bell" },
      { name: "Data Input/Prompt", pluginName: "Data Input/Prompt", logo: "FileInput" },
      { name: "Script Execution", pluginName: "Script Execution", logo: "Terminal" },
      { name: "Loop/Repeat", pluginName: "Loop/Repeat", logo: "RepeatIcon" },
      { name: "Data Transformation", pluginName: "Data Transformation", logo: "FileText" },
      { name: "Save/Export", pluginName: "Save/Export", logo: "Save" },
      { name: "Integration", pluginName: "Integration", logo: "Puzzle" },
      { name: "Subroutine", pluginName: "Subroutine", logo: "GitFork" },
    ]
  }
]

import axios from 'axios';

const BASE_API_URL = 'https://mp-gateway.elgato.com/products';
const CACHE_KEY = 'pluginCache';
const CACHE_EXPIRY = 24 * 60 * 60 * 1000; // 24 hours

export interface Plugin {
  id: string;
  name: string;
  app_icon: string;
  description: string;
  // ... other properties
}

export interface ApiResponse {
  results: Plugin[];
  total?: number; // Make total optional
}

export interface FetchPluginsParams {
  page: number;
  limit: number;
  sortBy: 'download_count' | 'created_at';
  sortOrder: 'asc' | 'desc';
  search?: string;
}

export async function fetchPlugins(params: FetchPluginsParams): Promise<ApiResponse> {
  const { page, limit, sortBy, sortOrder, search } = params;
  const offset = (page - 1) * limit;

  const url = new URL(BASE_API_URL);
  url.searchParams.append('extension', 'plugins');
  url.searchParams.append('limit', limit.toString());
  url.searchParams.append('offset', offset.toString());
  url.searchParams.append('ordering', `${sortOrder === 'desc' ? '-' : ''}${sortBy}`);
  if (search) {
    url.searchParams.append('search', search);
  }

  try {
    const response = await axios.get<ApiResponse>(url.toString());
    return response.data;
  } catch (error) {
    console.error('Error fetching plugins:', error);
    throw error;
  }
}